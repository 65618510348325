import { SvgIcon } from '@mui/material';

const DashboardIcon = () => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.82 23.4711C2.804 23.4711 0 20.5551 0 16.3831V7.09506C0 2.92206 2.8 0.00805664 6.82 0.00805664H16.707C20.725 0.00805664 23.528 2.92206 23.528 7.09506V16.3831C23.528 20.5551 20.728 23.4711 16.707 23.4711H6.82ZM16.703 21.0451C19.41 21.0451 21.092 19.2591 21.092 16.3831V7.09506C21.092 4.21906 19.41 2.43306 16.703 2.43306H13.017V21.0451H16.703ZM2.433 16.3831C2.433 19.2581 4.113 21.0451 6.821 21.0451H10.587V13.5321H10.527H2.432L2.433 16.3831ZM10.587 11.1151V2.43304H6.82C4.113 2.43304 2.432 4.21904 2.432 7.09505V11.1131H10.498C10.528 11.1124 10.5577 11.1131 10.587 11.1151L10.587 11.1151Z"
        fill="#0090FF"
      />
    </SvgIcon>
  );
};

export default DashboardIcon;
