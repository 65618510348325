import { Box, Container as BaseContainer, styled } from "@mui/material";

export const Wrapper = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  background: theme.palette.primary.dark,
  position: "relative",
  display: "flex",

  ".MuiTableCell-root": {
    borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
    whiteSpace: "nowrap",
    padding: "16px 28px",
    [theme.breakpoints.down(767)]: {
      padding: "10px 20px",
    },
  },
  ".MuiTableHead-root": {
    backgroundColor: "#37373c",
    ".MuiTableCell-root": {
      borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
      padding: "22px 28px",
      color: theme.palette.text.secondary,

      [theme.breakpoints.down(767)]: {
        padding: "10px 20px",
        fontSize: "13px",

        ".MuiTypography-label": {
          fontSize: "13px",
        },
        ".MuiTypography-body1": {
          fontSize: "13px",
        },
      },
    },
  },
  ".MuiTableBody-root": {
    [theme.breakpoints.down(767)]: {
      ".MuiTypography-body2": {
        fontSize: "13px",
      },
    },
  },

  ".MuiContainer-root": {
    maxWidth: "100%",
    width: "80% !important",
    margin: "0 0 0 auto",
    backgroundColor: "#181919",

    [theme.breakpoints.down(1440)]: {
      width: "75% !important",
    },

    [theme.breakpoints.down(999)]: {
      width: "100% !important",
    },
  },
  ".MuiPaper-root": {
    backgroundColor: "transparent",
    boxShadow: "none",
    border: "1px solid rgba(255, 255, 255, 0.1)",
    borderRadius: "10px !important",
    ".disablebutton": {
      background: "#0086ff",
      color: "#fff",
      border: "0",
      padding: "10px 30px",
      borderRadius: "10px",
      opacity: "0.6",
      [theme.breakpoints.down(1499)]: {
        padding: "10px 17px",
      },
      [theme.breakpoints.down(767)]: {
        padding: "10px 14px",
      },
    },
  },
  ".MuiButton-root": {
    padding: "10px 30px",
    borderRadius: "10px",
    fontSize: "16px",
    fontWeight: "500",
    background: "#0080ff",
    boxShadow: "none",
    color: "#fff",
    letterSpacing: "normal",
    [theme.breakpoints.down(1499)]: {
      padding: "10px 17px",
    },
    [theme.breakpoints.down(767)]: {
      fontSize: "12px",
      borderRadius: "10px",
      whiteSpace: "nowrap",
      padding: "10px 12px",
    },
    "&:hover": {
      background: "#0080ff",
      opacity: "0.7",
    },
  },
  ".disable": {
    // background:"red",
    opacity: "0.7",
  },
  ".MuiButton-outlined": {
    borderColor: theme.palette.blue.main,
    color: theme.palette.blue.main,

    "&:hover": {
      borderColor: theme.palette.blue.main,
    },
  },
  ".MuiButton-contained": {
    "&.primary-btn": {
      background: " linear-gradient(to right, #0068FF 50%, #00A7FF 100% )",

      "&:hover": {
        background: "transparent ",
        color: "#1677ff",
        borderColor: "#1677ff",
      },
    },
    "&.dark-btn": {
      background: theme.palette.secondary.dark,
      color: "#787A8D",
    },

    "&.Mui-disabled": {
      opacity: "0.7",
      color: "#fff",
      pointerEvents: "visible",
      cursor: "not-allowed !important",

      "&:hover": {
        opacity: "0.7",
      },
    },
  },
}));

export const Container = styled(BaseContainer)(({ theme }) => ({
  maxWidth: "100% !important",
  // width: ismobile === 'true' ? 'calc(100% - 60px)' : 'calc(100% - 400px)',
  // margin: ismobile === 'true' ? '30px' : '65px 60px 65px 30px',
  // marginLeft: '335px',
  padding: "65px 30px",

  // [theme.breakpoints.down(1680)]: {
  //   width: ismobile === 'true' ? 'calc(100% - 60px)' : 'calc(100% - 385px)',
  // },
  [theme.breakpoints.down(1440)]: {
    // margin: ismobile === 'true' ? '60px 30px 30px' : '45px',
    // marginLeft: '300px',
  },

  ".open-icon": {
    position: "absolute",
    top: "20px",
  },

  // '.MuiTableCell-body': {
  //   padding: '16px 30px',

  //   '.MuiTypography-body1': {
  //     padding: '10px 20px',
  //   },
  // },

  ".block-table": {
    ".MuiTableCell-head": {
      // '&:first-of-type': {
      //   paddingLeft: '98px',
      //   [theme.breakpoints.down(767)]: {
      //     paddingLeft: '85px',
      //   },
      // },
    },

    ".MuiTableCell-body": {
      padding: "16px 28px",
      [theme.breakpoints.down(767)]: {
        padding: "10px 20px",
      },
    },
  },

  ".validator-table": {
    backgroundColor: "#252527",
    // '.MuiTableCell-head': {
    //   paddingLeft: '16px',
    //   paddingRight: '16px',
    // },
  },

  ".custom-divider": {
    margin: "20px 0 15px",
    borderColor: "#1677ff",
  },

  ".primary-btn": {
    borderRadius: "15px",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    padding: "12px 18px",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transition: "all 0.25s linear",
    border: "1px solid",
    borderColor: "rgba(0, 104, 255, 1)",
    color: "white",
    svg: {
      path: {
        fill: theme.palette.text.primary,
        transition: "all 0.25s linear",
      },
    },
    "&:hover": {
      svg: {
        path: {
          transition: "all 0.25s linear",
          fill: "#1677ff",
        },
      },
    },

    [theme.breakpoints.down(767)]: {
      fontSize: "16px",
      padding: "8px 12px",
    },
  },
  ".outline-btn": {
    borderRadius: "15px",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    padding: "20px",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transition: "all 0.25s linear",
    background: "rgba(52, 108, 222, 0.2)",
    border: "1px solid",
    color: theme.palette.blue.main,
    borderColor: theme.palette.blue.main,

    svg: {
      path: {
        transition: "all 0.25s linear",
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
      borderColor: theme.palette.text.primary,
      color: theme.palette.text.primary,

      svg: {
        path: {
          transition: "all 0.25s linear",
          fill: theme.palette.text.primary,
        },
      },
    },
    [theme.breakpoints.down(767)]: {
      fontSize: "16px",
      padding: "12px",
    },
  },

  ".common-table": {
    "&.Bondtable": {
      marginTop: "15px",
      border: "1px solid rgba(255, 255, 255, 0.1);",
      ".title": {
        paddingTop: "10px",
        paddingBottom: "20px",
        marginLeft: "20px",
      },
    },
    // background:
    // " linear-gradient(88deg,hsla(0,0%,100%,0) 1.62%,hsla(0,0%,100%,.1) 48.43%,hsla(0,0%,100%,0) 98.38%)",
    borderRadius: "22px",
    paddingBottom: "35px",
    paddingTop: "10px",

    h3: {
      fontSize: "22px",
      padding: "30px 2px",
      paddingTop: "37px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down(767)]: {
        fontSize: "16px",
        padding: "20px",
      },
    },
    ".view-all": {
      fontSize: "16px",
      color: "#fff",
      textDecoration: "none",
      transition: "all 0.2s linear",

      "&:hover": {
        color: "#346CDE",
      },
    },
  },

  ".blockTab": {
    paddingTop: "0",
    h3: {
      paddingTop: "0",
    },
  },
  ".auth-wrapper": {
    ".addres-input": {
      marginBottom: "0",
      maxWidth: "fit-content",
    },
    "&__inner": {
      paddingTop: "115px",
      maxWidth: "885px",
      margin: "0 auto",

      [theme.breakpoints.down(1440)]: {
        paddingTop: "50px",
      },

      h3: {
        marginBottom: "5px",
      },

      ".address-field": {
        marginBottom: "20px",
      },
    },

    "&__backbtn": {
      textAlign: "right",
    },
    "&__btns": {
      display: "flex",
      gap: "0 30px",
      paddingTop: "50px",
      [theme.breakpoints.down(767)]: {
        flexDirection: "column",
        gap: "15px 0",
      },

      ".primary-btn": {
        flex: "1",
        height: "68px",
      },

      ".outline-btn": {
        flex: "1",
      },
    },
  },

  ".btn-icon": {
    display: "inline-block",
    marginRight: "15px",
    lineHeight: "0",
  },

  ".id-wrap": {
    display: "flex",
    alignItems: "center",

    "&__icon": {
      width: "36px",
      height: "36px",
      borderRadius: "8px",
      marginRight: "25px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#515152",
      border: "1px solid rgba(255, 255, 255, 0.1)",
      fontWeight: "700",
      fontSize: "17px",
      color: "#fff",

      [theme.breakpoints.down(767)]: {
        width: "35px",
        height: "35px",
        fontSize: "16px",
      },

      "&--mr-0": {
        marginRight: 0,
      },
    },
  },

  ".vaildator-card": {
    backgroundColor: "#37373c",
    border: " 1px solid rgba(255, 255, 255, 0.1)",
    height: "100%",
    position: "relative",
    "&__main": {
      display: "flex",
      alignItems: "center",
      marginRight: "15px",
    },

    "&__icon": {
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      width: "58px",
      height: "58px",
      borderRadius: "10px",
      background: "#515152",
      border: "1px solid rgba(255, 255, 255, 0.1)",
      marginRight: "20px",
      flexShrink: "0",
      [theme.breakpoints.down(1680)]: {
        width: "45px",
        height: "45px",
      },

      svg: {
        width: "28px",
        height: "28px",

        [theme.breakpoints.down(1680)]: {
          width: "18px",
          height: "18px",
        },
      },
    },
    "&__tooltip": {
      position: "absolute",
      top: "5px",
      right: "5px",

      ".MuiSvgIcon-fontSizeMedium": {
        fontSize: "18px",
      },
    },
  },

  ".not-found": {
    textAlign: "center",
    padding: "30px",

    img: {
      width: "250px",
      height: "250px",
      marginBottom: "20px",
      [theme.breakpoints.down(767)]: {
        width: "150px",
        height: "150px",
      },
    },

    " &__text": {
      fontSize: "18px",
    },
  },

  [theme.breakpoints.down(767)]: {
    ".MuiTypography-h3": {
      fontSize: "18px",
      marginBottom: "10px",
    },
    ".MuiTypography-body1": {
      fontSize: "14px",
    },
  },

  ".back-btn": {
    all: "unset",
    cursor: "pointer",
    opacity: "1",
    transition: "all linear 0.25s",

    "&:hover": {
      opacity: "0.7",
    },
  },

  ".circular-progress": {
    width: "15px !important",
    height: "15px !important",
    color: "#fff",
  },
  ".table-wrap": {
    position: "relative",

    ".nominate-btns": {
      position: "absolute",
      top: "-65px",
      right: "0",
    },

    ".MuiTableBody-root": {
      ".MuiTableRow-root": {
        cursor: "pointer",
        "&:hover": {
          background: "rgba(47, 71, 119, 0.1)",
        },
      },
    },
  },

  ".bonds-btns": {
    padding: "7px 20px",
    // border: "1px solid",
    borderRadius: "8px",
    // margin: "0 5px",
    textDecoration: "none",
    fontSize: "13px",
    display: "inline-block",
    transition: "all linear 0.25s",
    color: "#fff",
    fontWeight: "700 !important",
    "&.btn-yellow": {
      borderColor: "#CE9238",
      color: "#fff",
      margin: " 0 0px 0 10px",
      backgroundColor: "#CE9238",

      "&:hover": {
        color: "#ffffff",
        backgroundColor: "#F9B95999",
      },
    },
    "&.btn-green": {
      borderColor: "#1AD598",
      // color: '#1AD598',
      backgroundColor: "#1AD598",
      "&:hover": {
        backgroundColor: "#1AD59899",
      },
    },
    "&.btn-red": {
      borderColor: "#ED0800",
      // color: '#ED0800',
      backgroundColor: "#ED0800",
      marginLeft: "10px",
      "&:hover": {
        backgroundColor: "#ED080099",
      },
    },
    "&.btn-blue": {
      borderColor: "#0086ff",
      // color: '#346CDE',
      backgroundColor: "#0086ff",
      "&:hover": {
        backgroundColor: "#346CDE99",
      },
    },
  },
  ".status-active": {
    color: "#1AD598 !important",
  },
  ".status-inactive": {
    color: "#ED0800 !important",
  },
  ".status-waiting": {
    color: "#F9B959 !important",
  },

  ".tooltip-common": {
    ".MuiSvgIcon-fontSizeMedium": {
      fontSize: "12px",
    },
  },
  ".mt-5": {
    marginTop: "40px !important",
  },

  ".validator-address": {
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    maxWidth: "550px",
    marginBottom: "30px",

    ".addres-input": {
      marginLeft: "20px",
      marginBottom: "0",
      [theme.breakpoints.down(767)]: {
        maxWidth: "350px",
        marginLeft: "10px",
      },
      [theme.breakpoints.down(575)]: {
        maxWidth: "200px",
        marginLeft: "10px",
      },
    },
  },
  ".content-wrap": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 30px 30px",

    [theme.breakpoints.down(1440)]: {
      padding: "0 20px 20px",
    },
    [theme.breakpoints.down(574)]: {
      padding: "0 15px 15px",
    },
  },

  ".p-0": {
    padding: "0 !important",
  },
  ".text-hover": {
    cursor: "pointer",
    color: "#0086ff",
    display: "flex",
    alignItems: "center",
    gap: "4px",
    transition: "all 0.15s linear",
    "&:hover": {
      color: "#68afef",
    },
  },
}));
