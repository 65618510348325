import { BallTriangle } from "react-loader-spinner";
import React from "react";
import Lottie from "lottie-react";
import groovyWalkAnimation from "../../assets/data.json";

const Loader = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        // backgroundColor: 'rgba(0,0,0,0.7)',
        backgroundColor: "#000000",

        zIndex: 10000000000,
        position: "fixed",
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "35px",
      }}
    >
      <Lottie animationData={groovyWalkAnimation} loop={true} />
      {/* <BallTriangle color="#00BFFF" height={80} width={80} /> */}
    </div>
  );
};

export default Loader;
