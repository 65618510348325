import { SvgIcon } from '@mui/material';

const ValidatorIcon = () => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_5_5962)">
        <path
          d="M8 7.00049H22.5C22.8978 7.00049 23.2794 6.84245 23.5607 6.56115C23.842 6.27984 24 5.89831 24 5.50049C24 5.10266 23.842 4.72113 23.5607 4.43983C23.2794 4.15852 22.8978 4.00049 22.5 4.00049H8C7.60218 4.00049 7.22064 4.15852 6.93934 4.43983C6.65804 4.72113 6.5 5.10266 6.5 5.50049C6.5 5.89831 6.65804 6.27984 6.93934 6.56115C7.22064 6.84245 7.60218 7.00049 8 7.00049Z"
          fill="#fff"
        />
        <path
          d="M22.5 11H8C7.60218 11 7.22064 11.158 6.93934 11.4393C6.65804 11.7206 6.5 12.1022 6.5 12.5C6.5 12.8978 6.65804 13.2794 6.93934 13.5607C7.22064 13.842 7.60218 14 8 14H22.5C22.8978 14 23.2794 13.842 23.5607 13.5607C23.842 13.2794 24 12.8978 24 12.5C24 12.1022 23.842 11.7206 23.5607 11.4393C23.2794 11.158 22.8978 11 22.5 11Z"
          fill="#fff"
        />
        <path
          d="M22.5 18.0002H8C7.60218 18.0002 7.22064 18.1583 6.93934 18.4396C6.65804 18.7209 6.5 19.1024 6.5 19.5002C6.5 19.8981 6.65804 20.2796 6.93934 20.5609C7.22064 20.8422 7.60218 21.0002 8 21.0002H22.5C22.8978 21.0002 23.2794 20.8422 23.5607 20.5609C23.842 20.2796 24 19.8981 24 19.5002C24 19.1024 23.842 18.7209 23.5607 18.4396C23.2794 18.1583 22.8978 18.0002 22.5 18.0002Z"
          fill="#fff"
        />
        <path
          d="M2.5 8.00024C3.88071 8.00024 5 6.88096 5 5.50024C5 4.11953 3.88071 3.00024 2.5 3.00024C1.11929 3.00024 0 4.11953 0 5.50024C0 6.88096 1.11929 8.00024 2.5 8.00024Z"
          fill="#fff"
        />
        <path
          d="M2.5 14.5C3.88071 14.5 5 13.3807 5 12C5 10.6193 3.88071 9.5 2.5 9.5C1.11929 9.5 0 10.6193 0 12C0 13.3807 1.11929 14.5 2.5 14.5Z"
          fill="#fff"
        />
        <path
          d="M2.5 21.5002C3.88071 21.5002 5 20.3809 5 19.0002C5 17.6195 3.88071 16.5002 2.5 16.5002C1.11929 16.5002 0 17.6195 0 19.0002C0 20.3809 1.11929 21.5002 2.5 21.5002Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0_5_5962">
          <rect width="24" height="24" fill="white" transform="translate(0 0.000244141)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default ValidatorIcon;
