import { SvgIcon } from '@mui/material';

const RefreshIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <path
        d="M28.0003 28C27.6467 28 27.3076 27.8595 27.0575 27.6095C26.8075 27.3594 26.667 27.0203 26.667 26.6667V21.3333H21.3337C20.98 21.3333 20.6409 21.1929 20.3908 20.9428C20.1408 20.6928 20.0003 20.3536 20.0003 20C20.0003 19.6464 20.1408 19.3072 20.3908 19.0572C20.6409 18.8071 20.98 18.6667 21.3337 18.6667H28.0003C28.3539 18.6667 28.6931 18.8071 28.9431 19.0572C29.1932 19.3072 29.3337 19.6464 29.3337 20V26.6667C29.3337 27.0203 29.1932 27.3594 28.9431 27.6095C28.6931 27.8595 28.3539 28 28.0003 28ZM10.667 13.3333H4.00033C3.6467 13.3333 3.30756 13.1929 3.05752 12.9428C2.80747 12.6928 2.66699 12.3536 2.66699 12V5.33333C2.66699 4.97971 2.80747 4.64057 3.05752 4.39052C3.30756 4.14048 3.6467 4 4.00033 4C4.35395 4 4.69309 4.14048 4.94313 4.39052C5.19318 4.64057 5.33366 4.97971 5.33366 5.33333V10.6667H10.667C11.0206 10.6667 11.3598 10.8071 11.6098 11.0572C11.8598 11.3072 12.0003 11.6464 12.0003 12C12.0003 12.3536 11.8598 12.6928 11.6098 12.9428C11.3598 13.1929 11.0206 13.3333 10.667 13.3333Z"
        fill="white"
      />
      <path
        d="M15.9997 29.3333C12.7193 29.3339 9.55383 28.1252 7.10871 25.9384C4.66359 23.7516 3.11044 20.7401 2.74632 17.48C2.72706 17.3049 2.74248 17.1277 2.79169 16.9586C2.8409 16.7895 2.92295 16.6317 3.03314 16.4942C3.25568 16.2167 3.57937 16.0389 3.93299 16C4.28661 15.9611 4.6412 16.0643 4.91876 16.2868C5.19631 16.5094 5.37409 16.833 5.41299 17.1867C5.67654 19.5903 6.74892 21.833 8.45433 23.5472C10.1597 25.2614 12.3969 26.3452 14.7992 26.6211C17.2014 26.897 19.6261 26.3485 21.6756 25.0655C23.7252 23.7826 25.2781 21.8413 26.0797 19.56C26.1296 19.3846 26.2151 19.2214 26.3307 19.0803C26.4463 18.9393 26.5895 18.8235 26.7517 18.74C26.9138 18.6566 27.0913 18.6073 27.2733 18.5951C27.4552 18.583 27.6377 18.6083 27.8095 18.6696C27.9813 18.7308 28.1387 18.8266 28.2719 18.951C28.4052 19.0755 28.5115 19.226 28.5844 19.3932C28.6572 19.5603 28.6949 19.7407 28.6952 19.923C28.6956 20.1054 28.6585 20.2859 28.5863 20.4533C27.6649 23.0538 25.9594 25.3044 23.7051 26.8948C21.4507 28.4853 18.7586 29.3373 15.9997 29.3333ZM27.9197 16C27.59 16.002 27.2714 15.8818 27.0251 15.6627C26.7789 15.4435 26.6226 15.141 26.5863 14.8133C26.311 12.4212 25.2344 10.1927 23.5317 8.49014C21.8289 6.78761 19.6002 5.71127 17.2081 5.4362C14.816 5.16114 12.4012 5.70354 10.3565 6.97516C8.31173 8.24679 6.75741 10.1728 5.94632 12.44C5.89633 12.6154 5.8109 12.7786 5.69532 12.9197C5.57973 13.0607 5.43643 13.1765 5.2743 13.26C5.11217 13.3434 4.93464 13.3928 4.75269 13.4049C4.57074 13.417 4.38824 13.3917 4.21647 13.3304C4.0447 13.2692 3.88731 13.1734 3.75403 13.049C3.62076 12.9245 3.51443 12.774 3.44162 12.6068C3.36882 12.4397 3.33108 12.2593 3.33074 12.077C3.3304 11.8946 3.36747 11.7141 3.43966 11.5467C4.44356 8.69877 6.38444 6.27598 8.94465 4.67481C11.5049 3.07365 14.5327 2.38896 17.5328 2.73279C20.5328 3.07663 23.3273 4.42862 25.4588 6.56751C27.5904 8.70639 28.9328 11.5055 29.2663 14.5067C29.3038 14.8565 29.2015 15.2069 28.9817 15.4817C28.762 15.7564 28.4425 15.9331 28.093 15.9733L27.9197 16Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default RefreshIcon;
