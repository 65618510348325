import { SvgIcon } from '@mui/material';

const LoginIcon = () => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24">
      <g clipPath="url(#clip0_5_5964)">
        <path
          d="M11.5 16.0002C11.1022 16.0002 10.7206 16.1583 10.4393 16.4396C10.158 16.7209 10 17.1024 10 17.5002V18.3002C10 19.0163 9.71554 19.7031 9.20919 20.2094C8.70284 20.7158 8.01608 21.0002 7.3 21.0002H5.7C4.98392 21.0002 4.29716 20.7158 3.79081 20.2094C3.28446 19.7031 3 19.0163 3 18.3002V5.70024C3 4.98416 3.28446 4.2974 3.79081 3.79106C4.29716 3.28471 4.98392 3.00024 5.7 3.00024H7.3C8.01608 3.00024 8.70284 3.28471 9.20919 3.79106C9.71554 4.2974 10 4.98416 10 5.70024V6.50024C10 6.89807 10.158 7.2796 10.4393 7.5609C10.7206 7.84221 11.1022 8.00024 11.5 8.00024C11.8978 8.00024 12.2794 7.84221 12.5607 7.5609C12.842 7.2796 13 6.89807 13 6.50024V5.70024C12.9984 4.189 12.3974 2.7401 11.3288 1.67149C10.2601 0.602876 8.81125 0.00183242 7.3 0.000244141L5.7 0.000244141C4.18875 0.00183242 2.73986 0.602876 1.67125 1.67149C0.602632 2.7401 0.00158828 4.189 0 5.70024L0 18.3002C0.00158828 19.8115 0.602632 21.2604 1.67125 22.329C2.73986 23.3976 4.18875 23.9987 5.7 24.0002H7.3C8.81125 23.9987 10.2601 23.3976 11.3288 22.329C12.3974 21.2604 12.9984 19.8115 13 18.3002V17.5002C13 17.1024 12.842 16.7209 12.5607 16.4396C12.2794 16.1583 11.8978 16.0002 11.5 16.0002Z"
          fill="#fff"
        />
        <path
          d="M22.561 9.52464L17.975 4.93864C17.8357 4.79937 17.6703 4.68891 17.4882 4.61357C17.3062 4.53822 17.1111 4.49947 16.9141 4.49951C16.7171 4.49956 16.5221 4.53841 16.3401 4.61384C16.1581 4.68927 15.9928 4.79981 15.8535 4.93914C15.5722 5.22053 15.4143 5.60213 15.4144 5.99999C15.4144 6.19699 15.4533 6.39205 15.5287 6.57404C15.6041 6.75603 15.7147 6.92137 15.854 7.06064L19.265 10.4716L7 10.4996C6.60218 10.4996 6.22064 10.6577 5.93934 10.939C5.65804 11.2203 5.5 11.6018 5.5 11.9996C5.5 12.3975 5.65804 12.779 5.93934 13.0603C6.22064 13.3416 6.60218 13.4996 7 13.4996L19.318 13.4716L15.851 16.9386C15.5696 17.2199 15.4115 17.6014 15.4114 17.9993C15.4113 18.3971 15.5692 18.7787 15.8505 19.0601C16.1318 19.3415 16.5133 19.4997 16.9111 19.4998C17.309 19.4999 17.6906 19.3419 17.972 19.0606L22.558 14.4746C23.2136 13.818 23.5821 12.9282 23.5827 12.0003C23.5833 11.0723 23.2158 10.1821 22.561 9.52464Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0_5_5964">
          <rect width="24" height="24" fill="white" transform="translate(0 0.000244141)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default LoginIcon;
