import React from "react";
import { Row, Col } from "antd";
import github from "../../assets/Images/github.png";
import { Link } from "react-router-dom";
import {
  Uparrow,
  Facebook,
  Telegram,
  Twitter,
  Instagram,
} from "./FooterIconsvg.jsx";
import { FooterNew, GithubIcon } from "./style"; // Import your styled-components

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <FooterNew>
      <div className="container">
        <div className="footernew_topFooterItem">
          <div className="footernew_topFooterItem_socialIcon">
            <Link to="https://x.com/ShidoGlobal" target="_blank">
              <Twitter />
            </Link>
            <Link to="https://www.facebook.com/ShidoGlobal" target="_blank">
              <Facebook />
            </Link>
            <Link to="https://t.me/ShidoGlobal" target="_blank">
              <Telegram />
            </Link>
            <Link to="https://www.instagram.com/shidoglobal/" target="_blank">
              <Instagram />
            </Link>
            <Link to="https://github.com/ShidoGlobal" target="_blank">
              <GithubIcon src={github} alt="github" />
            </Link>
          </div>
          <div
            className="footernew_topFooterItem_backTopTop"
            onClick={scrollToTop}
          >
            <span className="rotateIcon">
              <Uparrow />
              Back to Top
            </span>
            <p></p>
          </div>
        </div>
        <Row gutter={[16, 16]} justify="center">
          <Col xs={24} sm={12} lg={12}>
            <h2>Powered By Shido</h2>
            <p>
              Build without limits. Shido combines the interoperability of the
              Cosmos, development power of EVM and WASM.
            </p>
          </Col>
          <Col xs={24} sm={12} lg={4}>
            <h3>About Us</h3>
            <Link to="" target="">
              Privacy Policy
            </Link>
            <Link to="" target="">
              Terms of Service
            </Link>
          </Col>
          <Col xs={24} sm={12} lg={4}>
            <h3>Product & Services</h3>
            <Link to="https://shidoscan.com/" target="_blank">
              Shido Explorer
            </Link>
            <Link to="https://delegator.shidoscan.com/" target="_blank">
              Shido Delegator App
            </Link>
            <Link to="https://app.shido.io" target="_blank">
              Shido DEX
            </Link>
            <Link to="https://shido.io/wallet" target="_blank">
              Shido App
            </Link>
          </Col>
          <Col xs={24} sm={12} lg={4}>
            <h3>Resources</h3>
            <Link to="https://shido.io" target="_blank">
              Official web
            </Link>
            <Link to="https://docs.shidoscan.com" target="_blank">
              Shido Network documentation
            </Link>
            <Link to="https://docs.shido.io" target="_blank">
              Shido Documentation
            </Link>
            <Link to="https://ide.shidoscan.com" target="_blank">
              Shido IDE
            </Link>
          </Col>
        </Row>
        <p className="footernew_year">Copyright© 2024 shido.io</p>
      </div>
    </FooterNew>
  );
};

export default Footer;
