import { SvgIcon } from '@mui/material';

const BlocksIcon = () => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24">
      <g clipPath="url(#clip0_5_5963)">
        <path
          d="M5.5 0.000244141H3.5C2.57174 0.000244141 1.6815 0.368993 1.02513 1.02537C0.368749 1.68175 0 2.57199 0 3.50024L0 5.50024C0.00226823 6.33796 0.305439 7.14697 0.854266 7.77987C1.40309 8.41276 2.16104 8.82741 2.99 8.94824V9.49324C2.98814 9.89055 3.14398 10.2724 3.42332 10.5549C3.70267 10.8374 4.0827 10.9976 4.48 11.0002C4.67707 11.0013 4.87241 10.9635 5.05487 10.889C5.23732 10.8146 5.40332 10.7049 5.54336 10.5662C5.68341 10.4276 5.79476 10.2627 5.87105 10.081C5.94734 9.89927 5.98708 9.70431 5.988 9.50724V8.95024C6.73251 8.84509 7.42305 8.50197 7.95653 7.9721C8.49001 7.44223 8.8378 6.75403 8.948 6.01024H9.492C9.88948 6.01237 10.2716 5.85665 10.5543 5.57727C10.8371 5.2979 10.9974 4.91772 11 4.52024C11.0011 4.32326 10.9633 4.128 10.8889 3.94561C10.8145 3.76322 10.7049 3.59727 10.5663 3.45723C10.4278 3.3172 10.263 3.20583 10.0814 3.12948C9.89983 3.05313 9.70498 3.01329 9.508 3.01224H8.95C8.83394 2.17894 8.42109 1.4155 7.7873 0.862175C7.15351 0.308847 6.34134 0.00279573 5.5 0.000244141V0.000244141ZM6 5.50024C6 5.63285 5.94732 5.76003 5.85355 5.8538C5.75979 5.94757 5.63261 6.00024 5.5 6.00024H3.5C3.36739 6.00024 3.24021 5.94757 3.14645 5.8538C3.05268 5.76003 3 5.63285 3 5.50024V3.50024C3 3.36764 3.05268 3.24046 3.14645 3.14669C3.24021 3.05292 3.36739 3.00024 3.5 3.00024H5.5C5.63261 3.00024 5.75979 3.05292 5.85355 3.14669C5.94732 3.24046 6 3.36764 6 3.50024V5.50024Z"
          fill="#fff"
        />
        <path
          d="M20.5 0.000244141H18.5C17.6623 0.00251237 16.8533 0.305683 16.2204 0.854511C15.5875 1.40334 15.1729 2.16129 15.052 2.99024H14.508C14.1105 2.98812 13.7285 3.14384 13.4457 3.42321C13.163 3.70259 13.0027 4.08277 13 4.48024C12.999 4.67723 13.0367 4.87249 13.1111 5.05488C13.1855 5.23727 13.2952 5.40322 13.4337 5.54325C13.5722 5.68329 13.737 5.79466 13.9186 5.87101C14.1002 5.94736 14.295 5.9872 14.492 5.98824H15.05C15.1556 6.7324 15.4989 7.42249 16.0287 7.95557C16.5585 8.48865 17.2465 8.83615 17.99 8.94624V9.49124C17.9876 9.8889 18.1432 10.2712 18.4226 10.5542C18.702 10.8372 19.0824 10.9976 19.48 11.0002C19.6771 11.0013 19.8724 10.9635 20.0549 10.889C20.2373 10.8146 20.4033 10.7049 20.5434 10.5662C20.6834 10.4276 20.7948 10.2627 20.8711 10.081C20.9474 9.89927 20.9871 9.70431 20.988 9.50724V8.95024C21.8213 8.83418 22.5848 8.42134 23.1381 7.78754C23.6914 7.15375 23.9975 6.34159 24 5.50024V3.50024C24 2.57199 23.6313 1.68175 22.9749 1.02537C22.3185 0.368993 21.4283 0.000244141 20.5 0.000244141ZM21 5.50024C21 5.63285 20.9473 5.76003 20.8536 5.8538C20.7598 5.94757 20.6326 6.00024 20.5 6.00024H18.5C18.3674 6.00024 18.2402 5.94757 18.1465 5.8538C18.0527 5.76003 18 5.63285 18 5.50024V3.50024C18 3.36764 18.0527 3.24046 18.1465 3.14669C18.2402 3.05292 18.3674 3.00024 18.5 3.00024H20.5C20.6326 3.00024 20.7598 3.05292 20.8536 3.14669C20.9473 3.24046 21 3.36764 21 3.50024V5.50024Z"
          fill="#fff"
        />
        <path
          d="M21.01 15.053V14.508C21.0119 14.1107 20.856 13.7289 20.5767 13.4463C20.2974 13.1638 19.9173 13.0036 19.52 13.001C19.323 12.9999 19.1276 13.0377 18.9452 13.1122C18.7627 13.1867 18.5967 13.2964 18.4567 13.435C18.3166 13.5737 18.2053 13.7386 18.129 13.9203C18.0527 14.102 18.0129 14.2969 18.012 14.494V15.051C17.268 15.1567 16.5782 15.5001 16.0453 16.03C15.5124 16.5598 15.1651 17.2476 15.055 17.991H14.511C14.314 17.9895 14.1185 18.0269 13.9359 18.1011C13.7533 18.1752 13.5871 18.2845 13.4468 18.4229C13.3065 18.5613 13.1948 18.7259 13.1181 18.9075C13.0415 19.089 13.0013 19.2839 13 19.481C12.999 19.678 13.0367 19.8732 13.1111 20.0556C13.1855 20.238 13.2952 20.404 13.4337 20.544C13.5722 20.684 13.737 20.7954 13.9186 20.8718C14.1002 20.9481 14.295 20.988 14.492 20.989H15.05C15.1661 21.8223 15.5789 22.5857 16.2127 23.1391C16.8465 23.6924 17.6587 23.9984 18.5 24.001H20.5C21.4283 24.001 22.3185 23.6322 22.9749 22.9759C23.6313 22.3195 24 21.4293 24 20.501V18.501C23.9978 17.6633 23.6946 16.8543 23.1458 16.2214C22.5969 15.5885 21.839 15.1738 21.01 15.053ZM21 20.501C21 20.6336 20.9473 20.7608 20.8536 20.8546C20.7598 20.9483 20.6326 21.001 20.5 21.001H18.5C18.3674 21.001 18.2402 20.9483 18.1465 20.8546C18.0527 20.7608 18 20.6336 18 20.501V18.501C18 18.3684 18.0527 18.2412 18.1465 18.1474C18.2402 18.0537 18.3674 18.001 18.5 18.001H20.5C20.6326 18.001 20.7598 18.0537 20.8536 18.1474C20.9473 18.2412 21 18.3684 21 18.501V20.501Z"
          fill="#fff"
        />
        <path
          d="M6.01 15.053V14.508C6.01186 14.1107 5.85602 13.7289 5.57668 13.4463C5.29733 13.1638 4.9173 13.0036 4.52 13.001C4.32293 12.9999 4.12759 13.0377 3.94513 13.1122C3.76268 13.1867 3.59668 13.2964 3.45664 13.435C3.31659 13.5737 3.20524 13.7386 3.12895 13.9203C3.05266 14.102 3.01292 14.2969 3.012 14.494V15.051C2.1787 15.1671 1.41526 15.5799 0.861931 16.2137C0.308603 16.8475 0.00255158 17.6597 0 18.501L0 20.501C0 21.4293 0.368749 22.3195 1.02513 22.9759C1.6815 23.6322 2.57174 24.001 3.5 24.001H5.5C6.33771 23.9987 7.14673 23.6956 7.77962 23.1467C8.41252 22.5979 8.82716 21.84 8.948 21.011H9.492C9.88948 21.0131 10.2716 20.8574 10.5543 20.578C10.8371 20.2987 10.9974 19.9185 11 19.521C11.0011 19.324 10.9633 19.1288 10.8889 18.9464C10.8145 18.764 10.7049 18.598 10.5663 18.458C10.4278 18.318 10.263 18.2066 10.0814 18.1302C9.89983 18.0539 9.70498 18.014 9.508 18.013H8.95C8.84485 17.2685 8.50172 16.5779 7.97185 16.0445C7.44198 15.511 6.75379 15.1632 6.01 15.053ZM6 20.501C6 20.6336 5.94732 20.7608 5.85355 20.8546C5.75979 20.9483 5.63261 21.001 5.5 21.001H3.5C3.36739 21.001 3.24021 20.9483 3.14645 20.8546C3.05268 20.7608 3 20.6336 3 20.501V18.501C3 18.3684 3.05268 18.2412 3.14645 18.1474C3.24021 18.0537 3.36739 18.001 3.5 18.001H5.5C5.63261 18.001 5.75979 18.0537 5.85355 18.1474C5.94732 18.2412 6 18.3684 6 18.501V20.501Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0_5_5963">
          <rect width="24" height="24" fill="white" transform="translate(0 0.000244141)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default BlocksIcon;
