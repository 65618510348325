import { useMediaQuery } from "@mui/material";
import { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Footer from "../Footer/Footer.jsx"
import Sidebar from "../Sidebar";
import Statistics from "../Statistics";
import theme from "../../theme";
import { toggleSidebar } from "../../redux/reducer/drawer";
import { Container, Wrapper } from "./styles";

import MenuIcon from "../../assets/Icons/MenuIcon";
import bgMain from "../../assets/Images/bgmain.png";

const MainPanel = ({ children }) => {
  const dispatch = useDispatch();

  const styles = {
    paperContainer: {
      // backgroundImage: `url(${bgMain})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top center ",
      backgroundSize: "cover",
    },
  };

  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const location = useLocation();

  const { pathname } = location;

  const showStats =
    pathname.includes("account/become-a-validator") ||
    !pathname.includes("/account");
  useEffect(() => {
    dispatch(toggleSidebar(!isMobile));
  }, [dispatch, isMobile]);

  const openSidebar = useCallback(() => {
    setIsMobileSidebarOpen(true);
  }, []);

  return (
    <>
    <Wrapper>
      <Sidebar
        isMobileSidebarOpen={isMobileSidebarOpen}
        setIsMobileSidebarOpen={setIsMobileSidebarOpen}
      />
      <Container style={styles.paperContainer} ismobile={`${isMobile}`}>
        {isMobile && (
          <MenuIcon
            className="open-icon"
            sx={{
              color: theme.palette.text.primary,
              fill: theme.palette.text.primary,
            }}
            onClick={openSidebar}
          />
        )}
        {showStats && <Statistics />}
        {children}
      </Container>
    </Wrapper>
    <Footer/>
    </>
  );
};

export default MainPanel;
