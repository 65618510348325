import { SvgIcon } from '@mui/material';

const CopyIcon = (props) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <g clipPath="url(#clip0_10_1579)">
        <path
          d="M18 13.4999V7.57093C18.0034 6.71662 17.8372 5.87013 17.511 5.08051C17.1849 4.2909 16.7053 3.57385 16.1 2.97093L15.025 1.89993C14.4221 1.29467 13.705 0.815067 12.9154 0.488922C12.1258 0.162777 11.2793 -0.0034315 10.425 -7.21461e-05H7.5C6.0418 0.00151602 4.64377 0.581489 3.61267 1.61259C2.58156 2.6437 2.00159 4.04173 2 5.49993V13.4999C2.00159 14.9581 2.58156 16.3562 3.61267 17.3873C4.64377 18.4184 6.0418 18.9983 7.5 18.9999H12.5C13.9582 18.9983 15.3562 18.4184 16.3873 17.3873C17.4184 16.3562 17.9984 14.9581 18 13.4999ZM5 13.4999V5.49993C5 4.83689 5.26339 4.201 5.73223 3.73216C6.20107 3.26332 6.83696 2.99993 7.5 2.99993H10.429C10.6205 3.00294 10.8115 3.02167 11 3.05593V4.99993C11 5.53036 11.2107 6.03907 11.5858 6.41414C11.9609 6.78922 12.4696 6.99993 13 6.99993H14.944C14.9783 7.18841 14.997 7.37938 15 7.57093V13.4999C15 14.163 14.7366 14.7989 14.2678 15.2677C13.7989 15.7365 13.163 15.9999 12.5 15.9999H7.5C6.83696 15.9999 6.20107 15.7365 5.73223 15.2677C5.26339 14.7989 5 14.163 5 13.4999ZM23 8.49993V18.4999C22.9984 19.9581 22.4184 21.3562 21.3873 22.3873C20.3562 23.4184 18.9582 23.9983 17.5 23.9999H9.5C9.10218 23.9999 8.72064 23.8419 8.43934 23.5606C8.15804 23.2793 8 22.8978 8 22.4999C8 22.1021 8.15804 21.7206 8.43934 21.4393C8.72064 21.158 9.10218 20.9999 9.5 20.9999H17.5C18.163 20.9999 18.7989 20.7365 19.2678 20.2677C19.7366 19.7989 20 19.163 20 18.4999V8.49993C20 8.1021 20.158 7.72057 20.4393 7.43927C20.7206 7.15796 21.1022 6.99993 21.5 6.99993C21.8978 6.99993 22.2794 7.15796 22.5607 7.43927C22.842 7.72057 23 8.1021 23 8.49993Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0_10_1579">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default CopyIcon;
