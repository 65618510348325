import styled from 'styled-components';

export const FooterNew = styled.section`
  background-color: #212122;
  padding: 16px 0px 5px;
  width: 100%;
  
  @media (max-width: 575px) {
    padding: 15px;
    margin-top: 30px;
  }
  
  .container {
    max-width: 1760px;
    width: 100%;
    margin: auto;
  }
  
  .footernew_topFooterItem {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(156, 144, 144, 0.1);
    padding-bottom: 12px;
    
    @media (max-width: 575px) {
      padding-top: 5px;
    }

    &_socialIcon {
      display: flex;
      gap: 8px;
      transition: none !important;
      
      a {
        background-color: #181819;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        .githubIcon {
          filter: invert(100%);
        }
      }
    }

    &_backTopTop {
      display: flex;
      background-color: transparent;
      border: none;
      cursor: pointer;
      gap: 8px;

      .rotateIcon {
        svg {
          rotate: 180deg;
          margin-right: 10px;
        }
      }
    }
  }

  .ant-row {
    padding: 10px 0 10px 0;
    
    a {
      color: #fff;
      font-size: 12px;
      padding-bottom: 6px;
      transition: transform 0.3s ease, color 0.3s ease, background-color 0.3s ease;
      
      @media (max-width: 575px) {
        padding-bottom: 8px;
      }

      display: block;

      &:hover {
        opacity: 0.7;
        color: #fff;
        transform: translateX(10px);
      }
    }

    h2 {
      color: #fff;
      display: flex;
      font-weight: 500;
      gap: 7px;
      padding-bottom: 0px;
      font-size: 22px;
      align-items: center;
    }

    h3 {
      color: #fff;
      display: flex;
      font-size: 17px;
      font-weight: 600;
      gap: 7px;
      padding-bottom: 0px;
    }

    p {
      font-size: 12px;
      font-weight: 300;
      max-width: 500px;
      word-break: break-word;
      margin-bottom: 0;
    }
  }

  p {
    color: #fff;
  }

  .footernew_year {
    text-align: center;
    border-top: 1px solid rgba(156, 144, 144, 0.1);
    padding: 16px 0 0 0;
    font-size: 12px;
  }
`;

export const GithubIcon = styled.img`
  filter: invert(100%);
  width: 16px;
  height: 16px;
`;

// Add other styled components as needed
