import { Box, styled } from "@mui/material";

export const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "30px",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "40px",

  [theme.breakpoints.down(1680)]: {
    ".MuiTypography-h4": {
      fontSize: "18px",
      wordBerak: "break-word",
    },
    ".MuiTypography-h6 ": {
      fontSize: "13px",
    },
  },
  [theme.breakpoints.down(1440)]: {
    gap: "15px",
  },

  ".icon-wrap": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "58px",
    height: "58px",
    borderRadius: "50%",
    flexShrink: "0",
    svg: {
      width: "25px",
      height: "25px",
    },

    [theme.breakpoints.down(1680)]: {
      width: "45px",
      height: "45px",
    },
    "&.firstbg": {
      background: "#FE6BBA33",
    },
    "&.secondbg": {
      background: "#1AD59833",
      svg: {
        height: "30px",
      },
    },
    " &.thirdbg": {
      background: "#0090FF33",
    },
    "&.fourthbg": {
      background: "#F3654A33",
    },
    "&.fifthbg": {
      background: "#F9B95933",
    },

    "&.icon-large": {
      ".MuiSvgIcon-fontSizeMedium": {
        fontSize: "34px",
      },
    },
  },

  ".supply-wrap": {
    display: "flex",
  },

  ".total-supply": {
    maxWidth: "150px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export const StatItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flex: "auto",
  background:
    "linear-gradient(to bottom right, rgb(31, 31, 35), rgb(37, 42, 45))",
  borderRadius: "14px",
  padding: "28px",
  border: " 1px solid hsla(0,0%,100%,.1)",

  ".MuiSvgIcon-root": {
    fontSize: "20px",
    fill: "transparent",
    flexShrink: "0",
  },
  [theme.breakpoints.down(1680)]: {
    padding: "20px",

    ".MuiSvgIcon-root": {
      fontSize: "18px",
    },
  },
  [theme.breakpoints.down(767)]: {
    width: "100%",
    wordBreak: "break-word",
    minHeight: "128px",
  },
}));
