import { SvgIcon } from '@mui/material';

const CalendarIcon = (props) => (
  <SvgIcon
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.92886 25H22.0711C23.9729 25 25.5185 23.5139 25.5185 21.6852V5.16664C25.5185 3.33794 23.9729 1.85182 22.0711 1.85182H20.7037V0.925909C20.7037 0.416643 20.2703 0 19.7407 0C19.211 0 18.7777 0.416643 18.7777 0.925909V1.85182H7.22222V0.925909C7.22222 0.416643 6.78883 0 6.2592 0C5.72956 0 5.29625 0.416643 5.29625 0.925909V1.85182H3.92886C2.02701 1.85182 0.481445 3.33794 0.481445 5.16664V21.6852C0.481445 23.5139 2.02701 25 3.92886 25ZM2.40742 5.16664C2.40742 4.36111 3.09111 3.70371 3.92886 3.70371H5.29625V4.62962C5.29625 5.13889 5.72956 5.55553 6.2592 5.55553C6.78883 5.55553 7.22214 5.13889 7.22214 4.62962V3.70371H18.7777V4.62962C18.7777 5.13889 19.211 5.55553 19.7407 5.55553C20.2703 5.55553 20.7036 5.13889 20.7036 4.62962V3.70371H22.071C22.9088 3.70371 23.5924 4.36111 23.5924 5.16664V7.87038H2.40742V5.16664ZM2.40742 9.7222H23.5926V21.6852C23.5926 22.4907 22.9089 23.1481 22.0712 23.1481H3.92886C3.09111 23.1481 2.40742 22.4907 2.40742 21.6852V9.7222Z"
      fill="#F3654A"
    />
  </SvgIcon>
);

export default CalendarIcon;
