import { SvgIcon } from '@mui/material';

const AccountIcon = () => {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <g clipPath="url(#clip0_10_1922)">
        <path
          d="M11.4527 14.019C7.19099 14.4043 3.94415 18.0053 4.00073 22.2841V22.501C4.00073 23.3295 4.67231 24.001 5.50073 24.001C6.32915 24.001 7.00073 23.3295 7.00073 22.501V22.2241C6.95563 19.5969 8.89438 17.3566 11.5007 17.0241C14.252 16.7513 16.7035 18.7604 16.9763 21.5117C16.9924 21.6743 17.0006 21.8376 17.0007 22.0011V22.501C17.0007 23.3295 17.6723 24.001 18.5007 24.001C19.3291 24.001 20.0007 23.3295 20.0007 22.501V22.001C19.9958 17.5778 16.4061 13.996 11.9829 14.0009C11.806 14.0011 11.6292 14.0071 11.4527 14.019Z"
          fill="#fff"
        />
        <path
          d="M12.001 12.0002C15.3147 12.0002 18.001 9.31393 18.001 6.00024C18.001 2.68656 15.3147 0.000244141 12.001 0.000244141C8.68729 0.000244141 6.00098 2.68656 6.00098 6.00024C6.00426 9.31257 8.68865 11.9969 12.001 12.0002ZM12.001 3.00024C13.6578 3.00024 15.001 4.3434 15.001 6.00024C15.001 7.65709 13.6578 9.00024 12.001 9.00024C10.3441 9.00024 9.00098 7.65709 9.00098 6.00024C9.00098 4.3434 10.3441 3.00024 12.001 3.00024Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0_10_1922">
          <rect width="24" height="24" fill="white" transform="translate(0 0.000244141)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default AccountIcon;
